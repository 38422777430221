import {
  Box,
  Flex,
  SkeletonText,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  useJsApiLoader,
} from '@react-google-maps/api'
import { MultiRouteMap } from "./MultiRouteMap";
import { useParams } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
import { getAssetsByFlagged } from './AssetsAPI'
import { isMobile } from 'react-device-detect';

const coreProps = {
  apiUrl: process.env.REACT_APP_BASE_URL, 
  authToken: process.env.REACT_APP_AUTH_TOKEN};

const libraries = ['places', 'drawing', 'marker', 'core'];

function AssetMapByFlagged() {
  const { flagged, organizationId } = useParams();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  })

  //const kMetersToMiles = 0.621371 / 1000.0;

  const [map, setMap] = useState(/** @type google.maps.Map */ (null))
  const [currentLocation, setCurrentLocation] = useState({ lat: 35.1, lng: -82.1 })
  const [center, setCenter] = useState({ lat: 35.1, lng: -82.1})
  const [numRouteOptions, setNumRouteOptions] = useState(0)
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [assetMap, setAssetMap] = useState(new Map());
  const [lastAssetTimestamp, setLastAssetTimestamp] = useState(1672531200000);  // default to 1/1/2023 00:00:00
  const [isLocalMobile, setIsLocalMobile] = useState(isMobile);
  const [detailsBackground, setDetailsBackground] = useState('#80ff8080');
  const [windowWidth, setWindowWidth] = useState('100%');
  const [windowHeight, setWindowHeight] = useState('100%');

  const logoMap = {
    'other': 'https://findjanko.locatorx.com/LXMarker.png',
  }
 
  const handleWindowSizeChange = () => {
    const wString = ''+window.innerWidth+'px';
    const hString = ''+window.innerHeight+'px';
    setWindowWidth(wString);
    setWindowHeight(hString);
    window.location.reload(false);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const fetchData = useCallback(async () => {

    const theTime = Date.now();
    console.log('fetchData at '+theTime+" "+lastAssetTimestamp+" "+currentLocation.lat+","+currentLocation.lng+" window: "+window.innerWidth+","+window.innerHeight);

    const results = await getAssetsByFlagged({...coreProps, flagged: flagged, organizationId: organizationId, lastAssetTimestamp: lastAssetTimestamp});

    if (results.assets && results.assets.length > 0) {
      console.log('**** have an asset update: '+results.assets[0].lastName+" "+results.assets[0].latitude+" "+results.assets[0].longitude);
        // eslint-disable-next-line no-undef
        var bounds = new google.maps.LatLngBounds();

        const productMap = results.productMap;

        results.assets.forEach((a) => {
        let iconMapSelect = logoMap;
        let detBgnd = '#80ff8080';
        let iconFile = iconMapSelect['other'];
        let marker;
        if (a.latitude != null && a.longitude != null) {
          if (!assetMap.has(a.assetId)) {
            // new asset
            // eslint-disable-next-line no-undef
            marker = new google.maps.Marker({
              position: { lat: a.latitude, lng: a.longitude },  // state variable
              map,
             icon: iconFile,
            });
            let contentString = '<table>';
//            contentString += '<tr><td style="padding-right:5px">Asset Name:</td><td>'+a.tag+'</td></tr>';
//            contentString += '<tr><td style="padding-right:5px">Unique Code:</td><td>'+a.externalId+'</td></tr>';
            contentString += '<tr><td style="padding-right:5px">Description:</td><td>' + a.propertiesMap.description + '</td></tr>';
            contentString += '<tr><td style="padding-right:5px">Last Event:</td><td>' + a.lastEvent + '</td></tr>';
            contentString += '<tr><td style="padding-right:5px">Facility:</td><td>'+a.facility+'</td></tr>';
            if (a.productId != null) {
              const p = productMap[a.productId];
              contentString += '<tr><td style="padding-right:5px">Product:</td><td>'+p.name+'</td></tr>';
              contentString += '<tr><td style="padding-right:5px">Manufacturer:</td><td>'+p.manufacturerName+'</td></tr>';
            }
            // if (a.customMap) {
            //   for (let key in a.customMap) {
            //     contentString += '<tr><td style="padding-right:5px">'+key+'</td><td>'+a.customMap[key]+'</td></tr>';
            //   }
            // }
            // contentString += '</table>';
            // contentString += '<hr></hr>';
            // contentString += '<table>';
            // if (a.classificationMap) {
            //   for (let key in a.classificationMap) {
            //     contentString += '<tr><td style="padding-right:5px">'+key+'</td><td>'+a.classificationMap[key]+'</td></tr>';
            //   }
            // }
            contentString += '</table>';

            // eslint-disable-next-line no-undef
            let infoWindow = new google.maps.InfoWindow({
              content: contentString,
              ariaLabel: "",
            })
            marker.addListener("mouseover", () => {
              infoWindow.open({
                anchor: marker,
                map,
              })
            })
            marker.addListener("mouseout", () => {
              infoWindow.close()
            })
          } else {
            const aProperty = assetMap.get(a.assetId)
            marker = aProperty.marker
            marker.setMap(null)
            marker.position = { lat: a.latitude, lng: a.longitude };
            marker.icon = iconFile;
            marker.setMap(map)
          }
          bounds.extend({ lat: a.latitude, lng: a.longitude });

          const aMap = { position: { lat: a.latitude, lng: a.longitude }, marker: marker };
          
          assetMap.set(a.assetId, aMap)
          setAssetMap(assetMap);
//          setCurrentLocation({ lat: a.latitude, lng: a.longitude });
          setLastAssetTimestamp(theTime);
          setDetailsBackground(detBgnd);
        
      }})
      map.fitBounds(bounds);
      
    } 
  }, [lastAssetTimestamp, currentLocation, assetMap, map])
  
  // the useEffect is only there to call `fetchData` at the right time
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData()
      // make sure to catch any error
      .catch(console.error);

    }, 5000);
      
      return () => clearInterval(interval);

  }, [fetchData])

  if (!isLoaded) {
    return <SkeletonText />
  }
  
  function setGoogleMap(googleMap)
  {
    setMap(googleMap);
  }

     return (
    <Flex position='relative' flexDirection='column' alignItems='center' h='100vh' w='100vw' >
      <Box position='absolute' left={0} top={0} w={windowWidth} h={windowHeight}>
        <MultiRouteMap {...coreProps} center={center} currentLocation={currentLocation} isMobile={isLocalMobile} onLoad={setGoogleMap} directionsResponse={directionsResponse} numRouteOptions={numRouteOptions} />
      </Box>
    </Flex>
  )
}

export default AssetMapByFlagged
